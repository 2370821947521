/* Brand colors */
/* :root {
    --primary-color: #ffcc33;
    --secondary-color: #ce2013;
  } */
  
  /* Buttons */
  /* .btn-custom-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: #ff7365;
    margin:2em;
  } */
/*   
  .btn-custom-primary:hover,
  .btn-custom-primary:focus,
  .btn-custom-primary:active {
    background-color: #e6b800;
    border-color: #e6b800;
    color: #ffffff;
  }
  
  .btn-custom-secondary {
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: #ffffff;
  }
  
  .btn-custom-secondary:hover,
  .btn-custom-secondary:focus,
  .btn-custom-secondary:active {
    background-color: #cc0000;
    border-color: #cc0000;
    color: #ffffff;
  } */

/*Font styles */

  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;700&family=Noto+Sans:ital,wght@0,300;1,600&family=Playfair+Display:ital,wght@0,400;1,500&display=swap');


:root {
  --golden-brown: #DAA520;
  --warm-gold: #DAA520;
  --mediterranean-blue: #007BA7;
  --vibrant-green: #45c012;
  --rich-maroon: #800000;
  --neutral-gray: #D3D3D3;
  --primary-red: #be2938;
  --primary-yellow: #ffcc33;
  --warm-beige: #FFEBAA;
  --sunset-orange: #FF4500;
  --off-white: #F5F5F5;
  --unieros-red:#ff0000;
  
}

/* Apply to elements */
body {
  background-color: var(--off-white);
  font-family: 'Noto Sans', sans-serif;
  line-height: 22px;
}

btn-custom-primary {
  background-color: var(--warm-gold);
  color: var(--neutral-gray);
}

a {
  color: var(--golden-brown);
  text-decoration: none;
  font-weight: 700;
}

  /*Headings */
  h1 {
    font-family:'Playfair Display', serif;
    font-weight: 500;
  }

  h2 {
    font-family: 'Noto Sans', sans-serif;
    font-style:italic;
    /* text-transform: uppercase; */
    font-weight: 600;
    line-height: 1.6em;
    color: #B8860B; /* Adjusted golden brown for better balance */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }

  h3.display-3 {
    font-weight: 700;
  }
  .lead {
    font-weight: 600;
  }
  .page-title {
    padding: 10px;
    margin:0px;
    color: var(--primary-red);
   text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
   text-transform: uppercase;
   font-weight: 700;
  }
  .highlight {
    margin-top: 1em;
  }

  /*Heaeder */
  .header {
    background-color: #f2f2f2; /* light grey background for the header */
  }
  /* Navigation Links */
.nav-link {
  text-transform: uppercase;
  font-weight: 700;
}
  .navbar {
    /* background-color: var(--warm-gold); */
    background-color:#ffcc33; 
  }
  
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #ff0000;
  }
  
  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: #2c110f;
  }
  #navbarNav {
    margin-right: 2em;
  }
  
  /* Homepage Hero */

.homepage {
  margin-bottom: 0px;
}
.hero {
  background-size: cover;
  background-position: center center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}
h1.hero-title {
  margin-top: 1em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 

}

.hero-button {
  background-color: #d73333;
  color: white;
  margin: 0.5em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  border: 1px solid #d73333;;
}


.jumbotron-heading {
  text-transform: uppercase;
  letter-spacing: .2em;

}
.welcome-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color:#ffffff;
}

.welcome-lead{
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color:#ffffff;
}
.homepage-background {
  background-image: url('../src/assets/images/treasure_fruits.jpg'); 
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #ffcc33;
}
.home-explore {
  padding: 0 15%;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  font-size: 1.2em;
}

.video-highlights {
  background-color: #f8f9fa;
  padding: 0 5%;

}
.peach {
  color: #ff7365;
}
/* Tech page */
.hero-tech {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 15vh;
  padding:2% 5%;
  background-color: #f0f0f0
 
}

.hero-tech img {
  max-width: 100%;
  height: 100%;
 object-fit: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}
.tech-desc {
  padding: 2% 5%;
}

.tech-services {
  padding:2% 5%;
}
.card-body i {
  font-size: 2em; /* Increase the size of the icon */
  color: #9a9480; /* Change the color of the icon */
  margin-bottom: 10px; /* Add some space below the icon */
}

/*Brand Modal */
.brand-modal .modal-header {
  background-color: #ffcc33;
  color: #ff0000;
}

.brand-modal .modal-footer {
  background-color: #ff0000;
  color: #fff;
}

.brand-modal .btn-secondary {
  background-color: #ffcc33;
  color: #fff;
}
/* Newsletter Signup */
.newsletter-signup {
  padding: 20px;
  background-color: #ffcc33; /* Muted Yellow */
  border-radius: 5px;
  text-align: center;

}

.newsletter-signup h3 {
  color: #333333;
  font-weight: 700;
  margin-bottom:20px;
}

/* Testimonial */
.testimonial-name {
  font-size: 12px;
  color: #ce2013;
}

/*Past Events */
.event-title {
  color: #ce2013;
}

.event-card {
  background: #ffcc33;
}

/* Footer */
footer {
  background: #ce2013;
  color:#e6b800;
  padding:2em;

}

/* Upcoming Events */
.upcoming-event {
 margin-top:2em;


}

/* Testimonials  */
.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem 0;
}

.testimonial {
  background-color: #fbfbfb;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 1rem;
  padding: 2rem;
  position: relative;
  width: 300px;
}

.testimonial-content::before {
  content: "“";
  font-size: 3em;
  font-weight: bold;
  color: #f7e738;
  position: absolute;
  top: -20px;
  left: -20px;
  font-family: "Georgia", serif;
}

.testimonial-content::after {
  content: "”";
  font-size: 3em;
  font-weight: bold;
  color: #ff5f6d;
  position: absolute;
  bottom: -20px;
  right: -20px;
  font-family: "Georgia", serif;
}

.testimonial-content {
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonial-author {
  font-weight: bold;
}
.social-icons {
  display:flex;
  align-items: center;
}
.social-icons .nav-item {
  margin-right: 10px; /* Adjust spacing as needed */
}

.social-icons .nav-link {
  display: flex;
  align-items: center;
  color: inherit; /* Ensure the icon inherits the color */
 transition: 0.3s ease, transform 0.3s ease;
}

.social-icons .nav-link .fa-facebook-f {
  color: #4267B2; /* Facebook blue */
}

.social-icons .nav-link .fa-instagram {
  color: #C13584; /* Instagram gradient color */
}

.social-icons .nav-link:hover {
  color: #ff0000; /* Change to desired hover color */
  transform: scale(1.1); /* Slightly enlarge the icon */
}

.social-icons .nav-link .fa-facebook-f {
  color: #4267B2; /* Facebook blue */
}

.social-icons .nav-link .fa-instagram {
  color: #C13584; /* Instagram gradient color */
}
@media (max-width: 767px) {
  .testimonials {
    flex-direction: column;
  }

  .testimonial {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
    .hero {
      background-size: cover;
      background-position: center center;
      min-height: 60vh;
    }
    .hero-tech {
      display: block;
      flex-direction: unset;
    align-items: center;
    justify-content: center;
    min-height: 15vh;
    padding:2% 5%;
   
    }
  }
/* Slider styles */
.slider-container {
  background-color: #f0f0f0; /* Light grey background */
  padding: 20px;
  border-radius: 8px; /* Optional: adds rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for depth */
}

p.fw-bold {
  margin-top: 20px; /* Adds margin to the top */
  margin-bottom: 20px; /* Adds margin to the bottom */
}
/* Card */
.card-section {
  background-color: #ffffff; /* white background for the card section */
}

.card-hover:hover {
  transform: scale(1.05); /* scale the card on hover */
  transition: transform 0.3s ease;
}

.card {
  border-radius: 20px;
  overflow: hidden; /* to apply border radius to the image */
  border: 1px solid #ffcc33; /* yellow border for the card */
}

.card-img-top {
  object-fit: cover;
  height: 200px; /* adjust as needed */
}

.card-body {
  background-color: #ffffff; /* white background for the card body */
  color: #333333; /* dark grey text color */
}

.card-title {
  color: #ff0000; /* red text color for the title */
  font-weight: 700;
}
.card-category {
  text-transform: uppercase;
  font-weight: 700;
}

/* Profile */

.profile-desc {
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: #f8f9fa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .profile-desc h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }
  
  .profile-desc p {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: #666;
  }

  /* Misc */

  .red-link {
    color: #ce2013;
    text-decoration: underline;
  }
  .list-group-item-green {
    background-color: #45c012;
  }
  .list-group-item-yellow {
    background-color: #fee231;
  }
  .bi-calendar-month-fill, .bi-geo-alt, .bi-star, .bi-music-note-beamed, .bi-cup-straw, .bi-airplane-engines, .Card.Title{
    color: #ff7365;
  }
  .hero-sponsor {
    background: linear-gradient(to right, #ff7365, #ffcc33);
    padding: 2em;
  }
  
  .red-quote {
    color:#CE2013;
  }
 .signature-events{
  display:grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap:10px;
 }
 .signature-events img {
  width: 100%;
  height:auto;
 }
.display-6, .display-4 {
  text-transform: uppercase;
}
.reservation {
  border: 2px solid #333;
  border-radius:3px;
  background-color: #cdcdcd;
}
.cap-link {
  text-transform: uppercase;
  font-weight: 700;
}
.tourAmeneties {
  background-color:var(--warm-beige);
  padding: 2em;
  margin-bottom: 3em;
  text-align: left;
}
.whats-included, .whats-excluded {
  margin-bottom: 3em;
}

.whats-included li, .whats-excluded li{
  list-style: none;
  margin-bottom:1em;
}
.whats-included li::before, .whats-excluded li::before{
  font-weight: 900;
}
.reservation {
  border: 1px solid #666;
  border-radius: 6px;
  background-color: #ededed;
}
.accordion-button:not(.collapsed) {
  color: var(--warm-beige);
  background-color: var(--vibrant-green);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 #da0e31;
}
/* ABout Page */
.about-section {
  margin: 20px 0;
}

.about-section h2 {
  color: #ff0000; /* red color for the title */
  margin-bottom: 15px;
}

.about-section p {
  line-height: 1.6; /* increase line height for better readability */
  color: #333333; /* dark grey color for the text */
}

.about-section a {
  color: #ffcc33; /* yellow color for the links */
}

.about-section img {
  width: 100%; /* make the image responsive */
  height: auto;
}

.btn-unieros {
  background-color: #ff0000; /* Brand red */
  color: #ffffff; /* White text color for better contrast */
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn-unieros:hover {
  background-color: #388E3C; /* Darker shade of green on hover */
  color: #ffcc33; /* Brand yellow for text to maintain brand color presence */
}

/* FreeSignups.css */
.form-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-container input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #0056b3;
}

.form-container p {
  color: green;
  font-weight: bold;
}

/* Signup Form */
#mc_embed_signup {
  background: #ffcc33;
  font: 14px Helvetica, Arial, sans-serif;
}

/* Sponsor */
.sponsor-options{
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
}

.sponsor-options li {
  border: 1px solid #000; /* Add border */
  padding: 10px; /* Add padding */
  margin-bottom: 10px; /* Add space between boxes */
}

.sponsor-options li:hover {
  background-color: #ffcc33; /* Change background color on hover */
}
.sponsor-options li {
  font-weight: 700;
}
.sponsor-options p {
  font-weight: 300;
  font-size:0.8em;
}

.video-responsive {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*Timeline */
/* Custom styles for modal */
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  position: relative;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-primary {
  background-color: #ff0000;
  color: #ffcc33;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #ff5f6d;
}
