/* Blog.css */
.blog-container {
    background-color: #f8f9fa; /* Light background color for better readability */
    color: #333; /* Dark text color for contrast */
  }
  
  .card {
    border: none; /* Remove card border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  }
  
  .card-title {
    color: #333; /* Dark text color for titles */
  }
  
  .card-text {
    color: #555; /* Slightly lighter text color for body text */
  }
  
  .btn-unieros {
    background-color: #ffcc33; /* Brand color for buttons */
    color: #fff; /* White text color for buttons */
    border: none; /* Remove button border */
  }
  
  .btn-unieros:hover {
    background-color: #ff9900; /* Darker shade for hover effect */
    color: #fff; /* Ensure text color remains white */
  }
  
  .newsletter-signup {
    background-color: #fff; /* White background for newsletter signup */
    padding: 20px; /* Add padding for spacing */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  }