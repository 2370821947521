.membership-card {
    width: 350px;
    border: 2px solid #ffcc33;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-header {
    font-size: 24px;
    font-weight: bold;
    color: #ce2013;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .member-name,
  .unique-id {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .instructions {
    font-size: 14px;
  }
  
  .instructions h5 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  